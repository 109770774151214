import React from 'react';

import HeroImage from './hero-image';
import styles from './hero-image-takeover.module.scss';

/*
    A specific type of HeroImage that is larger and typically
    contains more content
*/

const HeroImageTakeover = props => {
  return (
    <HeroImage
      imgWrapperClass={styles.takeoverImgWrapper}
      heroOverlayClass={styles.takeoverOverlay}
      {...props}
    />
  );
};

export default HeroImageTakeover;
