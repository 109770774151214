import React from 'react';
import Layout from '../../../components/layout';
import { Link, graphql } from 'gatsby';
import styles from './index.module.scss';
import HeroImageTakeover from '../../../components/hero-image/hero-image-takeover';

const ContactUsSuccess = ({ data }) => {
  return (
    <Layout>
      <HeroImageTakeover fluid={data.file.childImageSharp.fluid}>
        <div className={`${styles.placementbox}`}>
          <div className={`${styles.placement}`}>
            <div className={`has-text-centered container ${styles.placement}`}>
              <div className={`${styles.thumbsup}`}>
                <h1
                  className={`title is-3 is-spaced ${styles.mainTitle} ${styles.heroHeading}`}
                >
                  Success!
                </h1>
                <p className={`content`}>
                  A Trustaff career specialist will be in touch shortly. If you
                  wish to speak to someone right away, please call us at{' '}
                  <b>877.880.0346</b>.
                </p>
                <Link className={`button is-info`} to="/" id="">
                  Home
                </Link>
                &nbsp;&nbsp;
                <Link className="button is-primary" to="/jobs/" id="jobs-cta">
                  Search jobs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </HeroImageTakeover>
    </Layout>
  );
};

export default ContactUsSuccess;

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "application-success/success_thanks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
