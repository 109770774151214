import React from 'react';
import Img from 'gatsby-image';

import styles from './hero-image.module.scss';

const HeroImage = ({ fluid, children, imgWrapperClass, heroOverlayClass }) => {
  return (
    <div className={styles.heroImageWrapper}>
      <Img
        fluid={fluid}
        className={`${styles.imgWrapper} ${imgWrapperClass}`}
      />
      <div className={`${styles.heroOverlay} ${heroOverlayClass} section`}>
        {children}
      </div>
    </div>
  );
};

HeroImage.defaultProps = {
  imgWrapperClass: '',
  heroOverlayClass: '',
};

export default HeroImage;
